import React, { useState } from "react";
import callApi from "../Util/apiCaller";
import { toast } from "react-toastify";
import waitListData from "../constants/waitListData.json";
import { Audio, TailSpin } from "react-loader-spinner";
const WaitList = ({ isBlackColor }) => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  console.log(isValid);
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // console.log(email);
  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmpty(value === "");
    setIsValid(validateEmail(value));
  };

  const handleEmailSent = async () => {
    if (isEmpty) {
      toast.error(`Please enter email address `, {
        position: "top-center",
        hideProgressBar: true,
      });
    } else if (isValid) {
      setLoader(true);
      let res = await callApi(`api/v1/customer/new-signup`, "post", {
        contact_email: email,
      });
      setLoader(false);
      if (res.status === "Success") {
        setEmail("");
        toast.success(`Success`, {
          position: "top-center",

          hideProgressBar: true,
        });
      } else if (res?.status == "Error") {
        toast.error(`Customer already exists`, {
          position: "top-center",
          hideProgressBar: true,
        });
      }
      //  else if (res.status == "Error") {
      // }
    } else
      toast.error(`Please enter a valid email address.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,

        // transition: Bounce,
      });

    // if () {
    //   alert("wefvh");
    // }
  };

  return (
    <div className="z-50" id={"waitlistSegment"}>
      <div
        className={
          isBlackColor
            ? "flex flex-col items-start"
            : `flex flex-col items-center justify-center gap-3 mt-32 md:mt-20 md:flex-row`
        }
      >
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html: waitListData.JoinTitle,
            }}
            className={
              isBlackColor
                ? "text-4xl font-semibold text-[#fff] font-Trap md:text-6xl md:font-bold  "
                : "text-5xl  font-semibold text-black font-Trap md:text-5xl md:font-bold"
            }
          ></p>
          <p
            style={{
              marginTop: "20px",
            }}
            dangerouslySetInnerHTML={{ __html: waitListData.JoinDescription }}
            className={
              isBlackColor
                ? " text-xl font-semibold text-[#fff] font-Trap md:text-base md:font-bold"
                : " text-xl font-semibold text-[black] font-Trap md:text-base md:font-bold"
            }
          ></p>

          <div
            className={
              isBlackColor
                ? "justify-start  gap-3 mt-8 md:flex"
                : " justify-center gap-5 mt-8 md:flex"
            }
          >
            <div
              className={
                isBlackColor
                  ? "h-12 border-solid border-[1px] border-[#fff]  w-[300px] rounded flex items-center"
                  : "h-12 border-solid border-[1px] border-black  w-[354px] rounded flex items-center"
              }
            >
              {/* <div className="">
                <h1 className="pl-2 text-base font-normal dark:text-white">
                  +91
                </h1>
              </div>

              <div className="h-9 w-[2px] bg-[#BFBEC1] mx-3"></div> */}

              {/* {check == true ? (
                <input
                  className="pl-[2px] text-base font-normal bg-[#232D3F] text-white border-none outline-none"
                  type="text"
                  placeholder="Enter mobile number"
                />
              )  */}

              <input
                style={{
                  paddingLeft: "12px",
                  paddingRight: "8px",
                  width: "100%",
                }}
                className={
                  isBlackColor
                    ? " text-base font-Trap text-[#fff] bg-[black]  font-normal text-blacks border-none outline-none"
                    : " text-base font-Trap text-[black] bg-[#fff]  font-normal text-blacks border-none outline-none"
                }
                type="text"
                placeholder="Enter your email"
                value={email}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={handleChange}
              />
            </div>

            <button
              style={{
                // backgroundColor: "#E157F233",
                border: "1px solid #E157F2",
              }}
              onClick={handleEmailSent}
              className={
                isBlackColor
                  ? " w-[300px]   bg-[#E157F233] h-12 md:w-[140px] md:px-3  mt-5 md:mt-0  rounded text-base font-medium 0 "
                  : "w-[356px]   bg-[#E157F233] h-12 md:w-[140px] md:px-3  mt-5 md:mt-0  rounded text-base font-medium 0"
              }
            >
              {loader ? (
                <div className="flex justify-center">
                  <TailSpin
                    visible={true}
                    height="40"
                    width="40"
                    color="#4fa94d"
                    // ariaLabel="tail-spin-loading"
                    // radius="1"
                    // wrapperStyle={{}}
                    // wrapperClass=""
                  />
                </div>
              ) : (
                <div className="">{waitListData.buttonText}</div>
              )}
            </button>
          </div>
        </div>
        {/* <div className="w-[1px]  h-36 mx-10 bg-[#BFBEC1] hidden md:block"></div>
        <div>
          <img
            className="hidden md:block w-[110px]"
            src="/img/Untitled 1 1.png"
            alt=""
          />
        </div> */}
      </div>
    </div>
  );
};

export default WaitList;
