import React from "react";
import { Helmet } from "react-helmet";
import Testimonial from "../components/Testimonial";
import WaitList from "../components/WaitList";
import homeData from "../constants/homeData.json";
import cardSliderData from "../constants/cardSilderData.json";
import ResumeDownloadSection from "../components/ResumeDownloadSection";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>{homeData.pageTitle}</title>
        <meta name="description" content={homeData.metaDescription} />
      </Helmet>
      <div className="text-center h-[800px]   md:h-[900px]   Perfect ">
        <div className="pt-24 md:pt-16">
          <h1
            dangerouslySetInnerHTML={{ __html: homeData.homePageTitle }}
            className="font-bold text-2xl md:text-[64px] md:leading-[75px] md:block hidden"
          ></h1>
          <h1
            dangerouslySetInnerHTML={{ __html: homeData.hometitle }}
            className="font-bold text-2xl md:text-[64px] md:leading-[75px] md:hidden block"
          ></h1>
          <h2
            dangerouslySetInnerHTML={{ __html: homeData.homePageDescription }}
            className="Open text-xl md:text-[28px] mt-20 md:mt-5 md:leading-9"
          ></h2>

          <div className="block mt-10 md:hidden">
            <button className=" w-40 py-3 bg-[#7C3AED] text-[#FDFBF9] font-bold rounded-3xl">
              {homeData.download}
            </button>
          </div>
          <div className="hidden md:block">
            <WaitList />
            {/* <button className="lato w-64 h-[54px] bg-[#551EC2] text-[#fff] rounded-3xl mt-16 md:mt-10">
              Download Now
              </button> */}
          </div>
        </div>
      </div>
      <div className="container px-4 mx-auto">
        <div className="  right-0 left-0 flex justify-center items-center  mt-16 absolute top-[500px]  md:top-[650px] ">
          <img
            className="hidden md:block"
            src="https://curriculo.gumlet.io/Frame+60461.png"
            alt="This is banner"
          />

          <img
            className="block md:hidden"
            src="/img/Frame 60488.png"
            alt="This is banner"
          />
        </div>
      </div>
      <div className="px-5 bottom-3  md:px-20 py-8 md:py-16 mt-[1100px] md:mt-[600px] background">
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
          className=" py-5 container mx-auto text-center rounded-3xl bg-[#fff]"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: homeData.resumeBuildTitle,
            }}
            className="roboto font-semibold md:font-bold text-2xl md:text-[32px]"
          ></p>
          <p className="mt-2 text-base font-normal md:text-lg ">
            {homeData.resumeBuildDescription1} <br />{" "}
            <span className="text-[#6930DB] font-bold text-lg">
              {homeData.spantext1}
            </span>
            {homeData.resumeBuildDescription2} {""}
            <br />
            <span className="text-[#6930DB] font-bold text-lg">
              {homeData.spantext2}
            </span>
            {""}
            {homeData.resumeBuildDescription3}
            {""}{" "}
            <span className="text-[#6930DB] font-bold text-lg">
              {homeData.spantext3}
            </span>
          </p>
          <div className="block mt-5 md:hidden">
            <button className="text-[#fff] rounded-3xl  w-56 py-3 bg-[#6930DB] text-sm">
              {homeData.download2}
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="container px-5 mx-auto">
          <p
            dangerouslySetInnerHTML={{
              __html: cardSliderData.cardText,
            }}
            className="roboto font-bold text-xl md:text-[40px] mt-16"
          ></p>
          <div className="relative h-[400px] mt-6 md:mt-10 pt-16 pb-4">
            <div className="container z-10 px-5 mx-auto">
              <div className="bg-[#5F22D9]  absolute left-0 -top-10 right-0 h-[330px] py-5  mt-10 "></div>
            </div>
            <div className="container px-0 mx-auto md:px-10 ">
              <div className="slider-container ">
                <Testimonial />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <ResumeDownloadSection />
      </div>
    </div>
  );
};

export default Home;
