import React from "react";
import resumedownload from "../constants/resumedownload.json";
const ResumeDownloadSection = () => {
  return (
    <div className="h-full py-10 mt-32 text-white bg-black ">
      {/* Header Section */}
      <div className="container px-5 mx-auto">
        <div className="flex flex-col items-center justify-center pt-10">
          <h1 className="mb-6 text-3xl font-bold">{resumedownload.text}</h1>
          <p className="mb-10 text-sm">{resumedownload.description}</p>
        </div>

        {/* Download Button */}
        <button className="px-6 py-2 mb-6 text-sm font-bold bg-transparent border border-white rounded-full">
          {resumedownload.download}
        </button>
      </div>

      {/* App Screenshots */}
      <div
        className="flex pt-10
       justify-end space-x-2"
      >
        {/* Screenshot 1 */}
        <div className="h-56 bg-purple-300 rounded-md w-28">
          <img
            src="/img/phone2.png"
            alt="PDF Viewer"
            className="object-cover w-full h-full rounded-md"
          />
        </div>
        {/* Screenshot 2 */}
        <div className="h-56 bg-purple-500 rounded-md w-28">
          <img
            src="/img/phone.png"
            alt="Curriculo Welcome"
            className="object-cover w-full h-full rounded-md"
          />
        </div>

        <div className="h-56 bg-purple-700 rounded-md w-28">
          <img
            src="/img/phone1.png"
            alt="Curriculo Editor"
            className="object-cover w-full h-full rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeDownloadSection;
