import React from "react";
import { Link } from "react-router-dom";
import WaitList from "./WaitList";
import FooterWaitList from "./FooterWaitList";
import { FaInstagram } from "react-icons/fa";
import { FaRedditAlien } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn, FaFacebook } from "react-icons/fa";
import { IconContext } from "react-icons";

import footer from "../constants/footer.json";
import linkData from "../constants/linkData.json";
// import moment from "react-moment";
import moment from "moment";
import "moment-timezone";
const Footer = () => {
  // const currentYear = moment().format("YYYY");
  const currentYear = moment().format("YYYY");
  return (
    <>
      <div className="bg-black hidden md:block  text-[#fff] mt-[450px] sm:mt-96  md:mt-48 py-14 px-10 ">
        <div className="">
          <FooterWaitList />
        </div>
        {/* <div className="!text-white"> */}
        {/* </div> */}
      </div>
      <div className="flex flex-col items-center justify-between h-20 px-8 mt-3 text-base font-normal md:flex-row md:flex roboto md:mt-0 ">
        <div className="flex items-center gap-2 list-none">
          <Link to={"/"}>
            <div>
              {/* <h1 className="text-3xl font-normal md:text-5xl roboto">LOGO</h1> */}
              {/* <img src="/img/Logo.png" alt="" /> */}
              <img src="https://curriculo.gumlet.io/logo_full.svg" alt="Logo" />
            </div>
          </Link>
          <p className="hidden text-xl md:block">
            {currentYear + footer.text}
            {/* © 2024 Curriculo. All rights reserved. */}
          </p>
        </div>

        <IconContext.Provider
          value={{ color: "#7c59c8", className: "global-class-name" }}
        >
          <div className="md:block hidden">
            <div className="flex   items-center gap-5 text-2xl cursor-pointer md:text-3xl">
              <Link to={linkData.meta}>
                <FaFacebook />
              </Link>
              <Link to={linkData.linkedin}>
                <FaLinkedinIn />
              </Link>
              <Link to={linkData.instagram}>
                <FaInstagram />
              </Link>
              <Link to={linkData.Reddit}>
                <FaRedditAlien />
              </Link>
              <Link to={linkData.x}>
                <FaXTwitter />
              </Link>
            </div>
          </div>
        </IconContext.Provider>
        <div>
          <p className="block md:hidden mt-2">{currentYear + footer.text}</p>
        </div>
        <div className="flex  flex-row items-center justify-center gap-16 mt-6 list-none md:flex md:flex-row md:gap-5 md:mt-0 ">
          <Link to={"/terms"}>
            <li>{footer.footerTerms}</li>
          </Link>
          <Link to={"/privacy"}>
            <li>{footer.footerPrivacy}</li>
          </Link>
          <Link to={"/support"}>
            <li>{footer.footerSupport}</li>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
