import React from "react";
import { Helmet } from "react-helmet";
import support from "../constants/support.json";
const Support = () => {
  return (
    <div>
      <Helmet>
        <title>{support.pageTitle}</title>
        <meta name="description" content={support.metaDescription} />
      </Helmet>
      <>
        <div className="flex items-center justify-center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfCc-nrr3XNUmx9o9tuZ18s-lH3QpSCyOdW1EtL4ulEUyhMTQ/viewform?embedded=true"
            width="540"
            height="600"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
      </>
    </div>
  );
};

export default Support;
