import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import navbar from "../constants/navbar.json";
const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getApp = () => {
    const { pathname } = location;
    if (pathname === "/") {
      scrollToApp();
    } else {
      navigate("/");
      setTimeout(scrollToApp, 300);
    }
  };

  const scrollToApp = () => {
    const element = document.getElementById("waitlistSegment");
    if (element) {
      var scrollDiv = element.offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  };

  return (
    <div className="py-2 ">
      <div className="flex items-center justify-between gap-3 px-2 md:gap-0 md:px-5">
        <Link to={"/"}>
          <div className="w-40 md:w-full">
            {/* <h1 className="text-3xl font-normal md:text-5xl roboto">LOGO</h1> */}
            {/* <img src="/img/Logo.png" alt="" /> */}
            <img src="https://curriculo.gumlet.io/logo_full.svg" alt="Logo" />
          </div>
        </Link>
        <div>
          <button
            onClick={getApp}
            className="lato w-40 md:w-64 h-10 md:h-[54px] bg-[#551EC2] text-[#fff] rounded-3xl"
          >
            {navbar.navbarButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
